/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link } from 'gatsby'

export const Header = ({ withoutMenu }) => (
  <header className="site-header">
    <div className="header-logo">
      <a href="/" title="Noé Gambini">
        n g
      </a>
    </div>
    {!withoutMenu ? <div className="header-menu button-open-sidenav">
      <a href="#">
        <span />
      </a>
    </div> :
      (
        <Link to="/">
          <div className="header-menu header-menu-back">
            <span />
          </div>
        </Link>
      )}
    <div className="header-follow">
      <ul>
        <li>
          <a href="https://www.linkedin.com/in/ngambini/" rel="noreferrer" target="_blank">
            <i className="ti-linkedin" />
          </a>
        </li>
        <li>
          <a href="https://github.com/NGambini" rel="noreferrer" target="_blank">
            <i className="ti-github" />
          </a>
        </li>
      </ul>
      <p>Follow Me</p>
    </div>
  </header >
)
