import React from "react"

export const Contact = ({ data }) => (
  <section
    id="contact"
    className="section section-contact section-contact-1 pp-scrollable"
  >
    <div className="display-screen">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="section-box">
              <div
                className="section-box-image overlay-image"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-content test">
              <div className="section-title section-title-3">
                <h2>
                  <span>{data.subtitle.text}</span>
                </h2>
                <h3>{data.title.text}</h3>
                <div className="divider divider-1" />
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.description.html,
                  }}
                />
              </div>
              <form
                className="form form-1"
                method="POST"
                name="Contact Form"
                data-netlify="true"
              >
                <input type="hidden" name="form-name" value="Contact Form" />
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6">
                    <div className="form-item">
                      <input name="name" type="text" id="form-item-name" />
                      <label htmlFor="form-item-name">
                        {data.your_name.text}
                      </label>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <div className="form-item">
                      <input name="email" type="email" id="form-item-email" />
                      <label htmlFor="form-item-email">
                        {data.your_email.text}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-item">
                      <textarea
                        name="message"
                        id="form-item-message"
                        defaultValue={""}
                      />
                      <label htmlFor="form-item-message">
                        {data.your_message.text}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="button button-md button-primary">
                      {data.submit_button.text}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
