/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import moment from 'moment-with-locales-es6';

import { Link } from "gatsby"

export const BlogPost = ({ data, alternateLng, locale, disqus }) => (
  <section
    id="blogPost"
    className="section section-blog section-blog-1 pp-scrollable"
  >
    <div className="display-screen">
      <div className="section-title-2">
        <div className="container">
          <div className="inner max-w-576">
            <div className="mini-section-title">
              <span>Blog</span>
            </div>
            <h1>{data.title.text}</h1>
            <div>
              <div className="divider divider-1-reverse divider-1-1" />
              <div className="divider divider-1-reverse divider-1-2" />
            </div>
            {alternateLng && <Link style={{
              display: 'inline-block',
              textDecoration: 'none',
              borderBottom: '1px solid rgb(226 226 226)',
              paddingBottom: '0.05rem',
              marginTop: '1rem',
              cursor: 'pointer'
            }} to={`/${alternateLng.lang.substr(0, 2)}/blog/${alternateLng.uid}`}>
              {locale === 'en-us' ? `Voir version française` : `View english version`}
            </Link>}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="post-item">
          <a>
            &nbsp;
          </a>
          <div className="post-content">
            <span className="post-icon ti-light-bulb" />
            <div className="post-data">
              <div className="blog-page-date">
                <i className="ti-time" />
                  {moment(data.date).locale(locale.substr(0, 2)).fromNow()}
                </div>
            </div>
            <div dangerouslySetInnerHTML={{
              __html: data.content.html
            }} />
          </div>
        </div>
        <div style={{ marginTop: '2rem' }}>
          {disqus}
        </div>

      </div>
    </div>
  </section>
)
