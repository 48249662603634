import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

export const About = ({ data }) => (
  <section
    id="about"
    className="section section-about section-about-1 pp-scrollable"
  >
    <div className="display-screen">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="section-box">
              <StaticQuery
                query={graphql`
                  query {
                    file(relativePath: { eq: "nono_cestadire_moi.png" }) {
                      childImageSharp {
                        # Specify the image processing specifications right in the query.
                        # Makes it trivial to update as your page's design changes.
                        fixed(width: 350, height: 350) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <div
                    className="section-box-image overlay-image"
                  >
                    <Img
                      alt="Moi"
                      desc="Moi"
                      style={{
                        border: "6px solid orange",
                        borderRadius: "50%",
                        margin: "auto auto",
                        display: "block"
                      }}
                      fixed={data.file.childImageSharp.fixed}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-content">
              <div className="section-title section-title-3">
                <h2>
                  <span>{data.subtitle.text}</span>
                </h2>
                <h3>
                  <span>{data.title.text}</span>
                </h3>
                <div className="divider divider-1" />
                <p>{data.description.text}</p>
              </div>
              <ul className="row">
                {data.skills &&
                  data.skills.map(s => (
                    <li key={s.title1.text} className="col-12 col-sm-12 col-md-6">
                      <div className="feature-item">
                        <span className={`icon ${s.icon.text}`} />
                        <div className="feature-item-content">
                          <h5>{s.title1.text}</h5>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: s.description1.html,
                            }}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
