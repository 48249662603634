import React from "react"
import Typed from "react-typed"

export const Hero = ({ data }) => (
  <section
    className="section section-hero section-hero-1 overlay-image"
  >
    <div className="hero-content">
      <div id="particles-js-two" className="particles-js" />
      <div className="container">
        <div className="row row-0">
          <div className="col-lg-12">
            <div className="hero-box">
              <p className="hero-icon">
                <span>{data.title.text}</span>
              </p>
              <h1>
                {data.i_am_a.text}&nbsp;
                <Typed
                  className="typed"
                  strings={data.jobs.map(j => j.title1.text)}
                  typeSpeed={100}
                  backSpeed={20}
                  loop
                />
                <br /> {data.based_in.text}
              </h1>
              <a href="#resume" className="button button-md button-primary">
                {data.button_primary.text}
              </a>
              <a href="#about" className="button button-md">
                {data.button_secondary.text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
