import React from "react"

export const Services = () => (
  <section
    id="services"
    className="section section-services section-services-1 pp-scrollable"
  >
    <div className="display-screen">
      <div className="section-title-2">
        <div className="container">
          <div className="inner max-w-576">
            <h2>
              <span>Services</span>
            </h2>
            <h3>Let's See My Services</h3>
            <div className="divider divider-1-reverse divider-1-1" />
            <div className="divider divider-1-reverse divider-1-2" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="owl-carousel carousel-services-1 carousel-style-1">
          <div className="item">
            <div className="inner">
              <span className="icon ti-cup" />
              <h3>UI/UX DESIGN</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit
                incididunt ut labore et dolore
              </p>
            </div>
          </div>
          <div className="item">
            <div className="inner">
              <span className="icon ti-crown" />
              <h3>WEB DESIGN</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit
                incididunt ut labore et dolore
              </p>
            </div>
          </div>
          <div className="item">
            <div className="inner">
              <span className="icon ti-ruler-pencil" />
              <h3>MOBILE APPS</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit
                incididunt ut labore et dolore
              </p>
            </div>
          </div>
          <div className="item">
            <div className="inner">
              <span className="icon ti-image" />
              <h3>CREATIVITY</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit
                incididunt ut labore et dolore
              </p>
            </div>
          </div>
          <div className="item">
            <div className="inner">
              <span className="icon ti-brush-alt" />
              <h3>GRAPHIC DESIGN</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit
                incididunt ut labore et dolore
              </p>
            </div>
          </div>
        </div>
      </div>
      <span className="section-circle-side left" />
    </div>
  </section>
)
