import React from "react"

export const Sidenav = ({ data, locale }) => (
  <div className="site-sidenav">
    <div className="sidenav-menu">
      <h2 className="logo">n g</h2>
      <button className="button button-close-sidenav"></button>
      <ul className="menu">
        <li>
          <a href="/#home">{data.home.text}</a>
          <i className="ti-arrow-right" />
        </li>
        <li>
          <a href="/#about">{data.about.text}</a>
          <i className="ti-arrow-right" />
        </li>
        <li>
          <a href="/#resume">{data.resume.text}</a>
          <i className="ti-arrow-right" />
        </li>
        <li>
          <a href="/#blog">{data.blog.text}</a>
          <i className="ti-arrow-right" />
        </li>
        <li>
          <a href="/#contact">{data.contact.text}</a>
          <i className="ti-arrow-right" />
        </li>
        <li>
          <a href={locale === "en-us" ? "/" : "/en"}>
            {locale === "en-us" ? "Version française" : "English version"}
          </a>
          <i className="ti-arrow-right" />
        </li>
      </ul>
    </div>
    <span className="sidenav-close" />
  </div>
)
