import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export const Blog = ({
  data,
  locale
}) => (
  <section
    id="blog"
    className="section section-blog section-blog-1 pp-scrollable"
  >
    <div className="display-screen">
      <div className="section-title-2">
        <div className="container">
          <div className="inner max-w-576">
            <h2>
              <span>Blog</span>
            </h2>
            <h3>Articles</h3>
            <div className="divider divider-1-reverse divider-1-1" />
            <div className="divider divider-1-reverse divider-1-2" />
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="owl-carousel carousel-blog-1 carousel-style-1"
          data-items={3}
          data-margin={30}
        >
          {data.map((post) => (
            <div key={post.node.data.title.text} className="post-item">
              <Link to={locale === 'en-us' ? `/en/blog/${post.node.uid}` : `/fr/blog/${post.node.uid}`}>
                {post.node.data.image_header.localFile && <GatsbyImage
                className="gatsby-post-image"
                image={post.node.data.image_header.localFile.childImageSharp.gatsbyImageData}
              />}

              </Link>
              <div className="post-content">
                <span className="post-icon ti-light-bulb" />
                <div className="post-data">
                  <h4 className="post-title">
                    <Link to={locale === 'en-us' ? `/en/blog/${post.node.uid}` : `/fr/blog/${post.node.uid}`}>
                      {post.node.data.title.text}
                    </Link>
                  </h4>
                  <div className="post-date">
                    <i className="ti-time" />
                    {post.node.data.date}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)
