import React from "react"

export const Resume = ({ data }) => (
  <section
    id="resume"
    className="section section-resume section-resume-1 pp-scrollable"
  >
    <div className="display-screen">
      <div className="section-title-2">
        <div className="container">
          <div className="inner max-w-576">
            <h2>
              <span>{data.subtitle.text}</span>
            </h2>
            <h3>{data.title.text}</h3>
            <div className="divider divider-1-reverse divider-1-1" />
            <div className="divider divider-1-reverse divider-1-2" />
          </div>
        </div>
      </div>
      <div className="container container-resume">
        <div className="row">
          <div className="col-md-6">
            <div className="resume-row">
              <h4 className="resume-title">{data.title_education.text}</h4>
              <ul>
                {data.education.map(e => (
                  <li key={e.name.text}>
                    <div className="resume-item">
                      <div className="resume-head">
                        <span className="resume-icon ti-pencil-alt" />
                        <h5>{e.name.text}</h5>
                        <h6>
                          {e.legend.text}
                          <small> ({e.dates.text})</small>
                        </h6>
                      </div>
                      <div className="resume-body">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: e.description.html,
                          }}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="resume-row">
              <h4 className="resume-title">Tech</h4>
              <ul>
                <li
                  className="resume-head"
                  style={{ paddingLeft: "30px", paddingTop: "30px" }}
                >
                  {data.tech.map(t => (
                    <div key={t.item.text}
                      style={{ display: "inline-block" }}
                      className="skills-item"
                    >
                      <div className="section-title section-title-3">
                        <h5>
                          <span>{t.item.text}</span>
                        </h5>
                      </div>
                    </div>
                  ))}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="resume-row">
              <h4 className="resume-title">{data.title_experience.text}</h4>
              <ul>
                {data.experience.map(e => (
                  <li key={e.company.text}>
                    <div className="resume-item">
                      <div className="resume-head">
                        <span className="resume-icon ti-pencil-alt" />
                        <h5>{e.company.text}</h5>
                        <h6>
                          {e.legend.text}
                          <small> ({e.dates.text})</small>
                        </h6>
                      </div>
                      <div className="resume-body">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: e.description.html,
                          }}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container"></div>
    </div>
  </section>
)
